import { Picture, Link } from '@components/ui'
import s from '../../Home.module.css'
import cn from 'classnames'
import { refLink } from '../../utils'
import Slide from '../Slide'

/**
 * Renders a card component.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.item - The item data.
 * @param {Object} props.shopCommon - The common shop data.
 * @param {string} props.title - The card title.
 * @param {number} props.index - The card index.
 * @returns {JSX.Element} The rendered card component.
 */
const Card = ({ item, shopCommon, title, index }) => {
  return (
    <div
      className={cn(
        s.picBox,
        'relative w-full overflow-hidden rounded-xl transition',
        {
          'col-span-1': item._span === '1/6',
          'col-span-2': !item._span || item._span === '1/3',
          'col-span-3': item._span === '1/2',
          'col-span-4': item._span === '2/3',
          'col-span-5': item._span === '5/6',
          'col-span-6': item._span === '1' || item._span === 1,
          'text-white': item._theme === 'dark' || item._pc_theme === 'dark',
        }
      )}
    >
      {item.tag && (
        <div className="absolute left-4 top-4 z-[1] flex flex-wrap gap-1">
          {Array.isArray(item.tag) ? (
            <>
              {item.tag.map((tag, index) => (
                <span
                  className="rounded-full bg-brand-sub-color px-[10px] py-1 text-sm font-semibold leading-[1.2] text-brand-color"
                  key={index}
                >
                  {tag}
                </span>
              ))}
            </>
          ) : (
            <span className="rounded-full bg-brand-sub-color px-[10px] py-1 text-sm font-semibold leading-[1.2] text-brand-color">
              {item.tag}
            </span>
          )}
        </div>
      )}
      <Link href={refLink(item.learn_more, title, 'learn_' + (index + 1))}>
        <Picture
          source={`${item.image_xl || item.image}, ${item.image} 1920`}
          className={cn(
            'w-full transition duration-300',
            {
              'aspect-h-[600] aspect-w-[916]': item.aspect === '916:600',
              'aspect-h-[600] aspect-w-[608]': item.aspect_xxl === '608:600',
            },
            s.imageOri
          )}
        />
      </Link>
      <div className="absolute bottom-[30px] left-0 box-border w-full px-5 text-center leading-[1.2] min-l:bottom-6 min-l:px-6 min-xl:bottom-8 min-xxl:bottom-10">
        <Link
          className="!no-underline"
          href={refLink(item.learn_more, title, 'learn_' + (index + 1))}
        >
          <h4
            className="text-2xl font-semibold !leading-[1.2] min-l:text-lg min-xl:text-2xl"
            dangerouslySetInnerHTML={{ __html: item.title }}
          ></h4>
        </Link>
        {item.description && (
          <p
            className={cn(
              'mt-[6px] text-[14px] font-medium !leading-[1.4] min-xl:mt-3 min-xl:text-base'
            )}
            dangerouslySetInnerHTML={{ __html: item.description }}
          ></p>
        )}
        <p
          className="mt-[6px] text-sm font-medium !leading-[1.4] opacity-75 min-l:mt-1 min-l:text-xs min-xl:text-base"
          dangerouslySetInnerHTML={{ __html: item.subtitle }}
        ></p>

        <div className="mt-[18px] flex items-center justify-center gap-[10px] min-l:mt-4 min-l:gap-4 min-xl:mt-6">
          {item.buy_now ? (
            <>
              {item.learn_more && (
                <Link
                  className={cn(
                    s.btnBasic,
                    '!px-[15px] min-l:!min-w-[132px] min-l:!text-[12px] min-xl:!px-[24px] min-xl:!py-[14px] min-xl:!text-[16px]',
                    {
                      [s.btnWhite]:
                        item._theme === 'dark' || item._pc_theme === 'dark',
                    }
                  )}
                  href={refLink(item.learn_more, title, 'learn_' + (index + 1))}
                >
                  {item.learn_more_text || shopCommon.learn_more}
                </Link>
              )}
              <Link
                className={cn(
                  s.btnPrimary,
                  '!px-[15px] min-l:!min-w-[132px] min-l:!text-[12px] min-xl:!px-[24px] min-xl:!py-[14px] min-xl:!text-[16px]',
                  {
                    [s.btnWhite]:
                      item._theme === 'dark' || item._pc_theme === 'dark',
                  }
                )}
                href={refLink(item.buy_now, title, 'buynow_' + (index + 1))}
              >
                {item.buy_now_text || shopCommon.buyNow}
              </Link>
            </>
          ) : (
            item.learn_more && (
              <Link
                className={cn(
                  s.btnPrimary,
                  'min-l:!min-w-[132px] min-l:!text-[12px] min-xl:!text-[16px]',
                  {
                    [s.btnWhite]:
                      item._theme === 'dark' || item._pc_theme === 'dark',
                  }
                )}
                href={refLink(item.learn_more, title, 'learn_' + (index + 1))}
              >
                {item.learn_more_text || shopCommon.learn_more}
              </Link>
            )
          )}
        </div>
      </div>
      {item.icons && (
        <div className="absolute right-4 top-4 flex items-center gap-2">
          {item.icons.map((icon, index) => (
            <div key={index} className="flex items-center gap-1">
              <Picture
                source={icon.icon}
                imgClassName="h-8 !w-auto min-l:h-10"
              />
              {icon.label && (
                <span
                  className="text-left text-[8px] font-semibold leading-[1.2] text-[#333]"
                  dangerouslySetInnerHTML={{
                    __html: icon.label,
                  }}
                ></span>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

const ProductCard = ({ section, locale, shopCommon, relatedProducts }) => {
  return (
    <>
      <div className="layer l:hidden">
        <div className="content">
          <div className="py-5 pt-10 min-l:py-10 min-l:pt-20 min-xxl:py-[60px] min-xxl:pt-[120px]">
            <h2
              className={s.title}
              dangerouslySetInnerHTML={{ __html: section.title }}
            ></h2>
            {section.lists && (
              <div className="mt-6 grid grid-cols-6 items-center justify-center gap-4 min-l:mt-8 min-xl:mt-12">
                {section.lists.map((item, index) => (
                  <Card
                    item={item}
                    shopCommon={shopCommon}
                    key={index}
                    title={section.title}
                    index={index}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <Slide
        section={section}
        locale={locale}
        shopCommon={shopCommon}
        relatedProducts={relatedProducts}
        className="text-center min-l:hidden"
        boxClassName="!pb-6"
      ></Slide>
    </>
  )
}

export default ProductCard
